.smart-main{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

}

p{
 font-style: oblique;

}
.smart-div{
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 20px;
    box-shadow: 3px 3px 4px 3px lightgray;
  
}
.smart-text{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    height: 350px;
   
}
.smart-image{
    width: 50%;
   
}
.smart-heading{
    width: 100%;
    text-align: center;
}
@media screen and (Max-width: 415px) {
    .smart-div{
     width: 90%;
    flex-direction: column;
    }
    .smart-text{
        width: 100%;
        height: 400px;
        }
    .smart-image{
        width: 100%;
      }
    
}
