.card{
    width: 250px;
    box-shadow: 2px 2px 4px 2px lightgray;
    /* border: solid red 2px; */
}
.news-div{
    display: grid;
    justify-content: center;
    gap: 30px;
grid-template-columns: repeat(3, 250px);
}
#news-img{
    width: 100%;
    height:100%;
}
.news-contanor{
    width: 100%;
    background-color:snow;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (Max-width: 415px) {
    .card{
        width: 250px;
        box-shadow: 2px 2px 4px 2px lightgray;
        /* border: solid red 2px; */
    }
   
   
    .news-div{
      
        gap: 30px;
    grid-template-columns: repeat(1, 250px);
    }
}